import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import login from 'src/pages/Login';
import withDisclaimer from 'src/utils/withDisclaimer';

// @TODO get server props
export const getServerSideProps = async ({ locale }) => ({
    props: {
        ...(await serverSideTranslations(locale, ['common', 'disclaimer'])),
        revalidate: 1,
    },
});

export default withDisclaimer()(login);
