/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styles from './styles.module.scss';
import { Form } from 'react-apoidea';
import RoundedButton from 'src/shared/components/RoundedButton';
import Link from 'src/shared/components/Link';
import RoutePaths from 'src/config/RoutePaths';
import PropTypes from 'prop-types';
import Message from 'src/shared/config/Message';
import { compose } from 'redux';
import { withTranslation } from 'next-i18next';
import GaService from 'src/services/GaService';
import GaCategory from 'src/config/GaCategory';
import GaAction from 'src/config/GaAction';

class LoginForm extends React.PureComponent {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            loading: false,
        };
        this.fields = [
            {
                controlId: 'email',
                label: t('login_email'),
                type: 'email',
                autoComplete: 'username',
                validate: {
                    email: true,
                    required: true,
                    message: t(Message.EMAIL_REQUIRED),
                },
            },
            {
                controlId: 'password',
                label: t('login_password'),
                type: { name: 'password', submitOnEnter: true },
                autoComplete: 'current-password',
                validate: {
                    required: true,
                    message: t(Message.FIELD_REQUIRED),
                },
            },
            // {
            //     controlId: 'rememberMe',
            //     label: t('login_remember_me'),
            //     type: 'checkbox',
            //     className: styles.rememberMe,
            // },
        ];
    }

    onClickForgotPasswordButton = () => {
        GaService.callEvent(GaCategory.login, GaAction.clickForgotPasswordButton);
    };

    onClickCreateAccount = () => {
        GaService.callEvent(GaCategory.login, GaAction.clickCreateAccountButton);
    };

    onSubmit = async (data) => {
        const { onSubmit } = this.props;
        this.setState({ loading: true });
        await onSubmit(data);
        this.setState({ loading: false });
    };

    renderSubmit = (submitFunc) => {
        const { type, t } = this.props;
        const { loading } = this.state;
        return (
            <div className={styles.buttonContainer}>
                <div>
                    <Link href={RoutePaths.FORGOT_PASSWORD}>
                        <a className={styles[type]} onClick={this.onClickForgotPasswordButton}>
                            {t('login_forgot_password_link')}
                        </a>
                    </Link>
                    <Link href={RoutePaths.REGISTER}>
                        <a className={styles[type]} onClick={this.onClickCreateAccount}>
                            {t('login_create_acct_link')}
                        </a>
                    </Link>
                </div>
                <RoundedButton loading={loading} type={type} onClick={() => submitFunc()}>
                    {t('login_button')}
                </RoundedButton>
            </div>
        );
    };

    render() {
        const { type, width } = this.props;
        return (
            <Form
                className={styles.container}
                style={{ width }}
                inputClassName={`${styles.input} ${styles[type]}`}
                fields={this.fields}
                onSubmit={this.onSubmit}
                submitRenderer={this.renderSubmit}
            />
        );
    }
}

LoginForm.defaultProps = {
    type: 'black',
    width: '340px',
    onSubmit: () => {},
};

LoginForm.propTypes = {
    type: PropTypes.string,
    width: PropTypes.string,
    onSubmit: PropTypes.func,
};

export default compose(withTranslation())(LoginForm);
