import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './styles.module.scss';
import { compose } from 'redux';
import LoginForm from 'src/components/LoginForm';
import LayoutWrapper from 'src/shared/components/LayoutWrapper';
import Section from 'src/shared/components/Section';
import AccountAPI from 'src/shared/services/api/AccountAPI';
import globalActions from 'src/shared/store/global/actions';
import Message from 'src/shared/config/Message';
import userActions from 'src/shared/store/user/actions';
import ReviewProfile from 'src/shared/components/ReviewProfile';
import withNoAuth from 'src/shared/utils/withNoAuth';
import UserStatus, { REVIEW_STATUS } from 'src/shared/config/UserStatus';
import ErrorService from 'src/shared/services/ErrorService';
import common from 'src/shared/utils/common';
import { withTranslation } from 'next-i18next';
import ExpireService from 'src/shared/services/ExpireService';
import ServerAPI from 'src/services/api/ServerAPI';
import GaService from 'src/services/GaService';
import GaCategory from 'src/config/GaCategory';
import GaAction from 'src/config/GaAction';
import MetaData from 'src/config/MetaData';
import RoutePaths from 'src/config/RoutePaths';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userStatus: null,
        };
        this.loginEmail = '';
        this.headerProps = { fixed: true, type: 'black' };

        this.reCaptchaRef = React.createRef();
    }

    async componentDidMount() {
        const { memberInfo } = this.props;

        const serverTime = await ServerAPI.getServerTime();
        common.setMomentOffset(serverTime);

        if (memberInfo && memberInfo.status) {
            if (memberInfo.expireDate && !ExpireService.checkIsExpire(memberInfo.expireDate)) {
                this.setState({ userStatus: memberInfo.status });
                if (memberInfo.token) {
                    this.getProfile(memberInfo.token);
                }
            } else {
                this.props.dispatch(userActions.logoutUser());
                this.props.dispatch(
                    globalActions.showAlertDialog({
                        title: this.props.t('idle_timeout_logout'),
                    })
                );
            }
        }
    }

    getProfile = async (token) => {
        const { dispatch } = this.props;
        const resp = await AccountAPI.getProfile(token);
        if (!resp.status) {
            const error = ErrorService.getError(resp);
            dispatch(globalActions.handleErrorDialog(error));
            return false;
        }
        if (resp.data && resp.data.status === UserStatus.APPROVED) {
            resp.data.status = UserStatus.APPROVED;
            await dispatch(userActions.updateUserInfo(resp.data));
            return true;
        }
        return false;
    };

    showErrorDialog = ({ error, message }) => {
        const { dispatch, t } = this.props;
        if (error && error.code === '200111') {
            // Account not activated
            dispatch(
                globalActions.showConfirmDialog({
                    title: t('account_not_activated_title'),
                    text: (
                        <>
                            <div>{t('account_not_activated_msg_1')}</div>
                            <br />
                            <div>{t('account_not_activated_msg_2')}</div>
                        </>
                    ),
                    cancelText: t('button_ok'),
                    confirmText: t('resend'),
                    shouldRemainAfterConfirm: true,
                    onConfirm: this.resendActivation,
                })
            );
            return;
        }

        dispatch(
            globalActions.showAlertDialog({
                text: message || t(Message.NETWORK_ERROR),
            })
        );
    };

    resendActivation = async () => {
        const { dispatch, t } = this.props;
        const resp = await AccountAPI.resendActivationEmail(this.loginEmail);

        dispatch(globalActions.dismissConfirmDialog());
        dispatch(
            globalActions.showAlertDialog({
                title: resp.status ? t('activation_sent_title') : null,
                text: resp.status ? t('activation_sent_msg') : resp.message,
                confirmText: t('button_ok'),
            })
        );
    };

    onSubmit = async ({ formData }) => {
        if (!this.reCaptchaRef.current) {
            return;
        }

        const { dispatch, t } = this.props;
        const obj = {
            recaptcha: await this.reCaptchaRef.current.executeAsync(),
            account: {
                email: formData.email,
                password: formData.password,
            },
        };
        this.loginEmail = formData.email;
        GaService.callEvent(GaCategory.login, GaAction.clickLoginButton);

        const resp = await AccountAPI.login(obj);
        this.reCaptchaRef.current.reset();

        if (!resp.status || !resp.data || !resp.data.basicInfo) {
            this.showErrorDialog(resp);
            GaService.callEvent(GaCategory.login, GaAction.loginFailed, resp.message);
        } else {
            GaService.callEvent(GaCategory.login, GaAction.loginSuccess);
            const info = common.convertLoginDataFormat(resp.data, resp.data.basicInfo.accountType);
            if (!info) {
                dispatch(
                    globalActions.showAlertDialog({
                        text: t(Message.GENERAL_ERROR),
                    })
                );
                return;
            }
            await dispatch(userActions.setUserInfo(info, formData.rememberMe === true));
            await this.setState({ userStatus: info.status });
        }
    };

    renderForm = () => {
        const { t } = this.props;
        return (
            <div className={styles.layer}>
                <p className={styles.title}>{t('login_title')}</p>
                <LoginForm onSubmit={this.onSubmit} />
            </div>
        );
    };

    render() {
        const { userStatus } = this.state;
        return (
            <LayoutWrapper className={styles.container} headerProps={this.headerProps} metaProps={MetaData[RoutePaths.LOGIN]}>
                <Section withFixedHeader>
                    {!REVIEW_STATUS.includes(userStatus) && this.renderForm()}
                    {REVIEW_STATUS.includes(userStatus) && (
                        <ReviewProfile status={userStatus} className={styles.review} image="/static/images/svg/ThankYou(Icon)@3x.svg" type="black" />
                    )}
                </Section>
                <ReCAPTCHA sitekey={process.env.RECAPTCHA_SITE_KEY} size="invisible" ref={this.reCaptchaRef} />
            </LayoutWrapper>
        );
    }
}

export default compose(withNoAuth({ isPage: true }), withTranslation())(Login);
